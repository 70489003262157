<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
import { Modal } from "bootstrap";
var state = store.state;
export default {
    page: {
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        PageHeader

    },
    data() {
        return {

            posSubscriptions: [],
            subscriptionPackages: [],
            page: 1,
            limit: 20,
            tot_pages: 0,
            searchHrLeaveTypes: null,
            PosSubscriptionsSearchMode: false,
            searchModel: "",
            state: state,
            pos_hubs: [],
            subscription_package_periods: [],
            subscription_packge: [],
            pos_hub_subscription: {},
            subscription_period_select: [],
            posHubSubscriptionModal: null

        };
    },
    methods: {


        getPosHubs() {
            this.http.get(`pos-hubs`, null, null, true).then((res) => {
                this.pos_hubs = res.data

            })
        },
        getPosHubSubscriptionPackagePeriods() {
            this.http.get(`subscription-packages`, null, null, true).then((res) => {
                this.subscription_package_periods = res.data,
                    console.log("ggg :", this.subscription_package_periods)
                this.subscription_period_select = this.subscription_package_periods.map((e) => { return { value: e.id, label: e.name } })

            })
        },


        addPosHubSubscription() {
            console.log("request :", this.pos_hub_subscription)
        },
        enable(app) {
            this.http.put(`pos-hub-subscriptions/activate-subscriptions`, app.id).then((res) => {

                if (res.status) {

                    this.popup.alert({
                        title: "messages.success",
                        msg: 'pos_subscriptions.subscription_is_activated',
                    });
                    this.get(this.page)
                }
            })
        },
        disable(app) {
            this.http.put(`pos-hub-subscriptions`, app.id, { is_active: 0 }).then(() => {
                this.get(this.page)
            })
        },
        editPosSubscriptions(app) {
            var data = {
                title: "popups.edit",
                inputs: [


                    {
                        model: "pos_hubs_id",
                        type: "select",
                        options: this.pos_hubs.map((e) => {
                            return { value: e.id, label: e.name }
                        }),
                        label: "pos_subscriptions.pos_hubs",
                        value: app.pos_hubs_id
                    },

                    {
                        model: "subscription_packge_id",
                        type: "select",
                        options: this.subscription_package_periods.map((e) => {
                            return { value: e.id, label: e.name }
                        }),
                        label: "pos_subscriptions.subscription_packge",
                        value: app.subscription_packge_id
                    },



                ],
                buttons: [
                    {
                        text: "popups.edit",
                        closer: true,
                        color: "primary",
                        handler: (obj) => {
                            this.http.put("pos-hub-subscriptions", app.id, obj).then(() => {
                                this.get(this.page);
                            });
                        },
                    },
                    {
                        text: "popups.cancel",
                        closer: true,
                        color: "danger",
                        handler: () => {
                            console.log("Like Clicked");
                        },
                    },
                ],
            };


            this.popup.modal(data);
        },
        search() {
            this.PosSubscriptionsSearchMode = true;
            this.tot_pages = 0;
            this.http
                .post("pos-hub-subscriptions/search", {
                    search: this.searchModel,
                    limit: this.limit,
                    page: this.page,
                    col: "name",
                })
                .then((res) => {
                    this.posSubscriptions = res.data;
                });
        },
        cancelappsearchMode() {
            this.searchModel = "";
            this.PosSubscriptionsSearchMode = false;
            this.get(this.page);
        },


        deletePosSubscriptions(app) {
            var data = {
                title: "popups.delete",
                msg: "popups.delmessage",
            };
            this.popup.confirm(data).then((resp) => {
                if (resp) {
                    this.http.delete("pos-hub-subscriptions", app.id).then(() => {
                        this.get(this.page);
                    });
                }
            });
        },

        get(page) {
            console.log(page);
            this.http
                .post("pos-hub-subscriptions/paginate", {
                    limit: this.limit,
                    page: page,
                    active: false

                })
                .then((res) => {
                    this.page = page;
                    this.tot_pages = Math.ceil(res.tot / this.limit);
                    this.posSubscriptions = res.data;
                });
        },
        openPosHubSubscriptionModal() {
            var modal = new Modal("#posHubRequest")
            this.posHubSubscriptionModal = modal

            this.posHubSubscriptionModal.show()


        }
    },
    created() {
        this.get(1);
    },
    mounted() {

        this.getPosHubs(),
            this.getPosHubSubscriptionPackagePeriods()


    },
};
</script>

<template>
    <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
        :title="$t('menu.menuitems.pos_hub_subscription_management.subItems.requests')" />

    <div class="row">
        <div class="col-6">

        </div>
        <!-- <div class="col-6" style="display: flex; justify-content: flex-end">
            <button class="btn-close text-light" v-if="PosSubscriptionsSearchMode" @click="cancelappsearchMode()"></button>
            <button type="button" @click="openPosHubSubscriptionModal()" class="btn btn-light float-end mb-4">
                <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
            </button>
        </div> -->
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
        <thead>
            <tr class="text-light text-center" style="background-color: #2a3042 !important">
                <th scope="col">{{ $t("pos_subscriptions.id") }}</th>
                <th scope="col">{{ $t("pos_subscriptions.pos_hub") }}</th>
                <th scope="col">{{ $t("pos_subscriptions.subscription_packge") }}</th>
                <th scope="col">{{ $t("pos_subscriptions.period") }}</th>
                <th scope="col">{{ $t("pos_subscriptions.type") }}</th>
                <th scope="col">{{ $t("pos_subscriptions.created") }}</th>
                <th scope="col">{{ $t("pos_subscriptions.is_active") }}</th>
                <th scope="col">{{ $t("pos_subscriptions.operations") }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(app, index) in posSubscriptions" :key="index" class="text-center">
                <td>{{ index + 1 }}</td>
                <td>{{ app?.pos_hub_name }}</td>
                <td>{{ $i18n.locale == "en" ? app?.name_en : app?.name }}</td>
                <td>{{ app?.period }}</td>
                <td>{{ app?.type == "m" && app?.period > 1 ? $t('pos_subscriptions.months') : app?.type == "m" &&
                    app?.period == 1 ? $t('pos_subscriptions.month') : app?.type == "y" && app?.period > 1 ?
                    $t('pos_subscriptions.years') : $t('pos_subscriptions.year') }}</td>
                <td>{{ new Date(app?.created).toLocaleString('en-GB') }}</td>


                <td>
                    <button @click="enable(app)" class="btn btn-success"> {{ this.$t("popups.accept") }}</button>

                </td>


                <td class="d-flex">
                    <a class="btn btn-primary mx-1" @click="editPosSubscriptions(app)" href="javascript: void(0);"
                        role="button">{{ $t("popups.edit") }}</a>


                </td>

            </tr>
        </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div>
        <!--   Apps  Pagination     -->
        <ul class="pagination pagination-rounded justify-content-center mb-2">
            <li class="page-item" :class="{ disabled: page == 1 }">
                <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
                    <i class="mdi mdi-chevron-left"></i>
                </a>
            </li>
            <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
                <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
                    p
                }}</a>
            </li>

            <li class="page-item" :class="{ disabled: page == tot_pages }">
                <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
                    <i class="mdi mdi-chevron-right"></i>
                </a>
            </li>
        </ul>
    </div>



    <!-- Modal Body -->
    <!-- if you want to close by clicking outside the modal, delete the last endpoint:data-bs-backdrop and data-bs-keyboard -->
    <div class="modal fade" id="posHubRequest" tabindex="-1" role="dialog" aria-labelledby="modalTitleId"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalTitleId">
                        {{ $t("popups.add") }}
                    </h5>
                </div>
                <div class="modal-body">


                    <div class="container">
                        <form>


                            <div class="row mb-3">
                                <label for="" class="form-label">{{ $t("pos_subscriptions.subscription_packge") }}</label>
                                <select v-model="pos_hub_subscription.pos_hub_packge_id" class="form-select form-select-lg"
                                    name="" id="">
                                    <option v-for="(item, index) in subscription_package_periods" :key="index"
                                        :value="item.id">{{ $i18n.locale == "ar" ? item.name : item.name_en }}</option>

                                </select>
                            </div>
                            <div class="row mb-3">
                                <label for="" class="form-label">{{ $t("pos_subscriptions.type") }}</label>
                                <select v-model="pos_hub_subscription.type" class="form-select form-select-lg" name=""
                                    id="">
                                    <option value="m"> {{ $t("pos_subscriptions.month") }}</option>
                                    <option value="y"> {{ $t("pos_subscriptions.year") }}</option>

                                </select>
                            </div>



                            <div class="mb-3 row">
                                <label for="inputName" class="col-4 col-form-label">{{ $t("pos_subscriptions.period")
                                }}</label>

                                <input type="number" v-model="pos_hub_subscription.period" class="form-control"
                                    name="inputName" id="inputName" />

                            </div>

                            <div v-if="pos_hub_subscription.type && pos_hub_subscription.period" class="row mb-3">
                                <label for="inputName" class="col-4 col-form-label">{{ $t("pos_subscriptions.total_price")
                                }}</label>

                                <h4> {{ pos_hub_subscription.type == "m" ?
                                    parseInt(subscription_package_periods.find(e => e.id ==
                                        pos_hub_subscription?.pos_hub_packge_id).monthly_price) *
                                    pos_hub_subscription.period : parseInt(subscription_package_periods.find(e => e.id ==
                                        pos_hub_subscription?.pos_hub_packge_id)?.annual_price) * pos_hub_subscription.period }}
                                    {{ $t("popups.ryial") }}</h4>

                            </div>

                        </form>
                    </div>




                </div>
                <div class="modal-footer">
                    <button @click="addPosHubSubscription" class="btn btn-primary">{{ $t("popups.add") }}</button>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                        {{ $t("popups.cancel") }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Optional: Place to the bottom of scripts -->




    <!--end-->
</template>

<style scoped>
.btn-close {
    position: relative;
    right: 317px;
    top: 6px;
    font-size: 15px;
}
</style>
